import React from 'react'
import Layout from '../components/layout'
import '../assets/main.css'
import { MDBContainer, MDBBtn } from 'mdbreact'

function Donate() {
  return (
    <Layout>
      <MDBContainer fluid>
        <div className="container plain-conatiner">
          <h1>Donate coming soon....</h1>
        </div>
      </MDBContainer>
    </Layout>
  )
}

export default Donate
